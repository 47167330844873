export default [
  //    - Reviews list
  {
    path: "/reviews",
    name: "foravila-reviews-list",
    component: () => import("@/views/reviews/list/ReviewsList.vue"),
    meta: {
      pageTitle: "Reviews list",
      breadcrumb: [{ text: "Bookings" }, { text: "Reviews", active: true }],
    },
  },
];
