export default {
  namespaced: true,
  state: {
    performancePeriod: null,
    performanceFilter: null,
    planningFilter: null,
    bookingsListFilter: null,
    calendarYear: null,
    calendarAccommodation: null,
  },
  getters: {
    showAccommodationName(state, getters, rootState) {
      return rootState.owner?.owner?.currentAccommodations?.length > 1 || false;
    },
    performancePeriod(state) {
      return state.performancePeriod;
    },
    performanceFilter(state) {
      return state.performanceFilter;
    },
    planningFilter(state) {
      return state.planningFilter;
    },
    bookingsListFilter(state) {
      return state.bookingsListFilter;
    },
    calendarYear(state) {
      return state.calendarYear;
    },
    calendarAccommodation(state) {
      return state.calendarAccommodation;
    },
  },
  mutations: {
    RESET(state) {
      state.performancePeriod = null;
      state.performanceFilter = null;
      state.planningFilter = null;
      state.bookingsListFilter = null;
      state.calendarYear = null;
      state.calendarAccommodation = null;
    },
    SET_PERFORMANCE_PERIOD(state, payload) {
      state.performancePeriod = payload;
    },
    SET_PERFORMANCE_FILTER(state, payload) {
      state.performanceFilter = payload;
    },
    SET_PLANNING_FILTER(state, payload) {
      state.planningFilter = payload;
    },
    SET_BOOKINGS_LIST_FILTER(state, payload) {
      state.bookingsListFilter = payload;
    },
    SET_CALENDAR_YEAR(state, payload) {
      state.calendarYear = payload;
    },
    SET_CALENDAR_ACCOMMODATION(state, payload) {
      state.calendarAccommodation = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    setPerformancePeriod({ commit }, payload) {
      commit("SET_PERFORMANCE_PERIOD", payload);
    },
    setPerformanceFilter({ commit }, payload) {
      commit("SET_PERFORMANCE_FILTER", payload);
    },
    setPlanningFilter({ commit }, payload) {
      commit("SET_PLANNING_FILTER", payload);
    },
    setBookingsListFilter({ commit }, payload) {
      commit("SET_BOOKINGS_LIST_FILTER", payload);
    },
    setCalendarYear({ commit }, payload) {
      commit("SET_CALENDAR_YEAR", payload);
    },
    setCalendarAccommodation({ commit }, payload) {
      commit("SET_CALENDAR_ACCOMMODATION", payload);
    },
  },
};
