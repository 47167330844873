export default [
  //    - Booking details
  {
    path: "/account",
    name: "foravila-account",
    component: () => import("@/views/account/Account.vue"),
    meta: {
      pageTitle: "My account",
      breadcrumb: [{ text: "Account", active: true }],
    },
  },
];
