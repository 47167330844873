/**
 * Format and return a currency amount in Humanize format
 * @param {Number} amount currency amount to format. The amount must be in int format 1€ = 100
 * @param {Boolean} rounded Wether the amount should be rounded or not. False by default
 */
export const formatCurrency = (amount, rounded = false) => {
  if (!amount || typeof amount !== "number") return null;

  const roundedAmount = Math.floor(amount / 100);
  const notRoundedAmount = amount / 100;

  return new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(rounded ? roundedAmount : notRoundedAmount);
};

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format. The date string must be in format YYYY-MM-DDTHH:MM:SS
 * @param {Object} formatting Intl object to format with
 */
export const formatDateStringToDate = (
  value,
  locale = "ca-ES",
  formatting = { month: "2-digit", day: "2-digit", year: "numeric" }
) => {
  if (!value || typeof value !== "string") return null;

  const stringDateArray = value.split("T");

  let dateArray = stringDateArray[0];
  if (!dateArray) return null;

  dateArray = dateArray.split("-");
  if (dateArray.length !== 3) return null;

  // Create a date with the extracted date form the dateString
  let date = new Date(
    parseInt(dateArray[0], 10), // Day
    parseInt(dateArray[1] - 1, 10), // MonthIndex
    parseInt(dateArray[2], 10) // Year
  );
  const formattedDate = new Intl.DateTimeFormat(locale, formatting).format(
    date
  );

  // If the stringDate does not include a valid time string, return the formatted date
  if (stringDateArray.length < 2) return formattedDate;
  let timeArray = stringDateArray[1];
  if (!timeArray) return formattedDate;
  // eslint-disable-next-line prefer-destructuring
  timeArray = timeArray.split("+")[0];
  if (!timeArray) return formattedDate;
  timeArray = timeArray.split(":");
  if (timeArray.length < 2) return formattedDate;

  // Create the date with the exctracted date and time from the dateString
  date = new Date(
    parseInt(dateArray[0], 10), // Day
    parseInt(dateArray[1] - 1, 10), // MonthIndex
    parseInt(dateArray[2], 10), // Year
    parseInt(timeArray[0], 10), // Hour
    parseInt(timeArray[1], 10) // Minute
  );

  return new Intl.DateTimeFormat(locale, formatting).format(date);
};

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {Date} value date to format. The date must be a javascript Date object
 * @param {Object} formatting Intl object to format with
 */
export const formatDateObjectToDate = (
  value,
  locale = "ca-ES",
  formatting = { month: "2-digit", day: "2-digit", year: "numeric" }
) => {
  if (!value || !(value instanceof Date)) return null;
  return new Intl.DateTimeFormat(locale, formatting).format(value);
};
