import axios from "@/axios";
import { UNCALCULABLE_FREQUENCIES } from "@/utils/consts";
// eslint-disable-next-line import/no-cycle
import { getBookingServiceOwnerTotalPrice } from "@/utils/methods";

export default {
  namespaced: true,
  state: {
    loading: false,
    booking: null,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    booking(state) {
      return state.booking;
    },
    accommodation(state) {
      return state.booking?.accommodation || null;
    },
    contract(state) {
      return state.booking?.contract || null;
    },
    details(state) {
      return state.booking?.details || null;
    },
    guests(state) {
      return state.booking?.guests || [];
    },
    confirmedGuests(state, getters) {
      return getters.guests.filter((guest) => !!guest.confirmed);
    },
    review(state) {
      return state.booking?.review || null;
    },
    services(state) {
      return state.booking?.services || [];
    },
    securityDeposit(state, getters) {
      const securityDepositServices = getters.services.filter(
        (bookingService) =>
          bookingService.service?.code &&
          bookingService.service.code.includes("SECURITY_DEPOSIT")
      );
      if (securityDepositServices.length === 0) return null;
      // Return the first security deposit service
      // TODO: the app should allow only to introduce 1 security deposit service to each booking
      return securityDepositServices[0];
    },
    securityDepositOwnerPrice(state, getters) {
      if (!getters.securityDeposit) return null;
      return getters.securityDeposit.costPrice;
    },
    hasSecurityDeposit(state, getters) {
      const securityDepositServices = getters.services.filter(
        (s) => s.service?.code && s.service.code.includes("SECURITY_DEPOSIT")
      );
      return securityDepositServices.length > 0;
    },
    hasChargableSecurityDeposit(state, getters) {
      if (!getters.securityDeposit) return false;
      return getters.securityDeposit.chargable;
    },
    hasManagedSecurityDeposit(state) {
      if (!state.booking) return false;
      return state.booking.managedSecurityDeposit;
    },
    ownerChargableServices(state, getters) {
      if (!getters.services.length) return [];
      return getters.services.filter((s) => {
        // If the payment frequency depends on consumption, the total price is uncalculabe,
        // that means that the service is not chargable
        if (
          s.paymentFrequency &&
          UNCALCULABLE_FREQUENCIES.includes(s.paymentFrequency)
        )
          return false;

        // If the booking service doesn't have a service related (that shouldn't happen),
        // only check if the booking service is chargable
        if (!s.service) return s.chargable;

        // If the booking service have a service related, check if the booking service is chargable,
        // and also check that the related service is not the security deposit, that the booking service
        // is not meant to be paid to the provider and that the booking service has a defined owner price
        const bookingServicePrice = getBookingServiceOwnerTotalPrice(
          s,
          state.booking?.nights
        );
        return (
          s.chargable &&
          s.service.code !== "SECURITY_DEPOSIT" &&
          s.paymentTime !== "PROVIDER" &&
          bookingServicePrice
        );
      });
    },
    ownerChargableServicesTotal(state, getters) {
      if (!getters.ownerChargableServices.length) return 0;
      return getters.ownerChargableServices.reduce((acc, cs) => {
        const servicePrice = getBookingServiceOwnerTotalPrice(
          cs,
          state.booking?.nights
        );
        return acc + (servicePrice || 0);
      }, 0);
    },
    ownerUnchargableServices(state, getters) {
      if (!getters.services.length) return [];
      return getters.services.filter((s) => {
        // If the payment frequency depends on consumption, the total price is uncalculabe,
        // that means that the service is unchargable
        if (
          s.paymentFrequency &&
          UNCALCULABLE_FREQUENCIES.includes(s.paymentFrequency)
        )
          return true;

        // If the booking service doesn't have a service related (that shouldn't happen),
        // only check if the booking service is not chargable
        if (!s.service) return !s.chargable;

        // If the booking service have a service related, check if the booking service is not chargable,
        // and also check that the related service is not the security deposit, that the booking service
        // is not meant to be paid to the provider and that the booking service has a defined owner price
        const bookingServicePrice = getBookingServiceOwnerTotalPrice(
          s,
          state.booking?.nights
        );
        return (
          !s.chargable &&
          s.service.code !== "SECURITY_DEPOSIT" &&
          s.paymentTime !== "PROVIDER" &&
          bookingServicePrice
        );
      });
    },
    ownerRates(state) {
      return state.booking?.ownerRates || [];
    },
    ownerSubtotal(state, getters) {
      if (getters.ownerRates.length === 0) return 0;
      return getters.ownerRates.reduce((acc, r) => acc + (r.price || 0), 0);
    },
    averageOwnerRatePerNight(state, getters) {
      if (!getters.ownerRates.length || !state.booking?.nights) return null;
      return getters.ownerSubtotal / state.booking.nights;
    },
    ownerTotal(state, getters) {
      let total = 0;

      if (getters.ownerSubtotal > 0) total += getters.ownerSubtotal;
      if (getters.ownerChargableServicesTotal > 0) {
        total += getters.ownerChargableServicesTotal;
      }

      // TODO: finish discount section
      // if (this.hasDiscount) total += -12000

      return total;
    },
    hasTouristTax(state, getters) {
      const touristTaxServices = getters.services.filter(
        (s) => s.service?.code && s.service.code.includes("TOURIST_TAX")
      );
      return touristTaxServices.length > 0;
    },
    touristTax(state, getters) {
      const touristTaxServices = getters.services.filter(
        (s) => s.service?.code && s.service.code.includes("TOURIST_TAX")
      );
      if (touristTaxServices.length === 0) return null;
      // Return the first tourist tax service
      // TODO: the app should allow only to introduce 1 tourist tax service to each booking
      return touristTaxServices[0];
    },
    touristTaxOwnerPrice(state, getters) {
      if (!getters.touristTax) return null;
      return getters.touristTax.costPrice;
    },
    isLastMinute(state) {
      if (!state.booking.policy || !state.booking.policy?.lastMinuteStart)
        return false;
      const checkinDate = state.booking.checkin.split("T")[0];
      const bookingDate = state.booking.date.split("T")[0];
      const hoursBetweenBookingAndCheckin =
        Math.abs(new Date(checkinDate) - new Date(bookingDate)) / 36e5;
      return (
        hoursBetweenBookingAndCheckin <= state.booking.policy.lastMinuteStart
      );
    },
    policy(state) {
      return state.booking?.policy || null;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.booking = null;
      state.loadingReview = false;
      state.review = null;
    },
    SET_LOADING_BOOKING(state, payload) {
      state.loading = payload;
    },
    SET_BOOKING(state, payload) {
      state.booking = payload;
    },
    SET_LOADING_REVIEW(state, payload) {
      state.loadingReview = payload;
    },
    SET_REVIEW(state, payload) {
      state.review = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    async fetchBookingByLocalizator({ commit }, localizator) {
      if (!localizator) {
        return new Promise((resolve, reject) => {
          reject(new Error("The localizator is null or empty"));
        });
      }

      commit("SET_LOADING_BOOKING", true);

      let bookingUri = null;

      try {
        bookingUri = await axios
          .get(`/bookings?localizator=${localizator}`)
          .then((response) => {
            if (response.data["hydra:totalItems"] > 0)
              return response.data["hydra:member"][0]["@id"];
            return null;
          })
          .catch(() => null);
      } catch (error) {
        commit("SET_LOADING_BOOKING", false);
        return new Promise((resolve, reject) => {
          reject(
            new Error(
              "Something went wrong while trying to fetch the booking uri"
            )
          );
        });
      }

      return new Promise((resolve, reject) => {
        if (!bookingUri) {
          commit("SET_LOADING_BOOKING", false);
          reject(
            new Error("The booking for the given localizator does not exist")
          );
          return;
        }

        try {
          axios
            .get(bookingUri)
            .then((response) => {
              commit("SET_BOOKING", response.data);
              resolve(response.data);
            })
            .catch((error) => {
              // TODO: Log error in Sentry
              reject(error);
            })
            .finally(() => commit("SET_LOADING_BOOKING", false));
        } catch (error) {
          commit("SET_LOADING_BOOKING", false);
          reject(error);
        }
      });
    },
    fetchReview({ commit }, reviewUuid) {
      if (!reviewUuid) return null;
      commit("SET_LOADING_REVIEW", true);
      return new Promise((resolve, reject) => {
        axios
          .get(`/reviews/${reviewUuid}`)
          .then((response) => {
            commit("SET_REVIEW", response.data);
            // The promise returns the review data
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
            // TODO: log the error with Sentry
          })
          .finally(() => commit("SET_LOADING_REVIEW", false));
      });
    },
  },
};
