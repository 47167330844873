import { $themeBreakpoints } from "@themeConfig";

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    loading: false,
    pageTitle: null,
    pageSubtitle: null,
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return "xl";
      if (windowWidth >= $themeBreakpoints.lg) return "lg";
      if (windowWidth >= $themeBreakpoints.md) return "md";
      if (windowWidth >= $themeBreakpoints.sm) return "sm";
      return "xs";
    },
    isMobile: (state, getters) =>
      ["xs", "sm"].includes(getters.currentBreakPoint),
    loading: (state) => state.loading,
    pageTitle: (state) => state.pageTitle,
    pageSubtitle: (state) => state.pageSubtitle,
    isDev: () => process.env.NODE_ENV === "development",
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay =
        val !== undefined ? val : !state.shallShowOverlay;
    },
    SET_LOADING(state, val) {
      state.loading = val;
    },
    SET_PAGE_TITLE(state, val) {
      state.pageTitle = val;
    },
    SET_PAGE_SUBTITLE(state, val) {
      state.pageSubtitle = val;
    },
  },
  actions: {
    setAppLoading(context, loading) {
      const appLoading = document.getElementById("loading-bg");
      if (appLoading) {
        if (loading) {
          appLoading.style.display = "block";
        } else {
          appLoading.style.display = "none";
        }
      }
    },
    setLoading({ commit }, loading) {
      commit("SET_LOADING", loading);
    },
    setPageTitle({ commit }, title) {
      commit("SET_PAGE_TITLE", title);
    },
    setPageSubtitle({ commit }, subtitle) {
      commit("SET_PAGE_SUBTITLE", subtitle);
    },
  },
};
