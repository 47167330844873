import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    updateUser({ commit }, user) {
      if (!user || !user.uuid) return null;
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);

        axios
          .patch(`/users/${user.uuid}`, user, {
            headers: {
              "Content-Type": "application/merge-patch+json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              localStorage.setItem(
                "foravilaOwnersUserData",
                JSON.stringify(response.data)
              );
              resolve(response.data);
            } else {
              reject(
                new Error(
                  "The request was successfull, but the received status was not the expected one"
                )
              );
            }
          })
          .catch((error) => {
            // TODO: log the error with Sentry
            reject(error);
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
  },
};
