import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    services: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    services(state) {
      return state.services;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.services = [];
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_SERVICES(state, payload) {
      state.services = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchServices({ commit }, payload = {}) {
      commit("SET_LOADING", true);

      let url = "/services?";
      if ("pagination" in payload) url += `pagination=${payload.pagination}`;

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            commit("SET_SERVICES", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data);
            // TODO: Log error in Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
  },
};
