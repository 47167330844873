export default [
  {
    code: "AIR_CONDITIONING_CORRIDOR",
    name_ca: "A/C passadís",
    name_es: "A/C pasillo",
    name_en: "Hallway A/C",
  },
  {
    code: "AIR_CONDITIONING_KITCHEN",
    name_ca: "A/C cuina",
    name_es: "A/C cocina",
    name_en: "Kitchen A/C",
  },
  {
    code: "AIR_CONDITIONING_LIVING_ROOM",
    name_ca: "A/C sala d'estar",
    name_es: "A/C salón",
    name_en: "Living room A/C",
  },
  {
    code: "BABY_CHAIR",
    name_ca: "Trona",
    name_es: "Trona",
    name_en: "Baby chair",
  },
  {
    code: "BABY_COT",
    name_ca: "Cuna",
    name_es: "Cuna",
    name_en: "Crib",
  },
  {
    code: "BALCONY",
    name_ca: "Balcó",
    name_es: "Balcón",
    name_en: "Balcony",
  },
  {
    code: "BARBECUE",
    name_ca: "Barbacoa",
    name_es: "Barbacoa",
    name_en: "Barbecue",
  },
  {
    code: "BATHROBE",
    name_ca: "Barnussos",
    name_es: "Albornoces",
    name_en: "Bathrobes",
  },
  {
    code: "BED_LINEN",
    name_ca: "Llençols",
    name_es: "Sábanas",
    name_en: "Bed linen",
  },
  {
    code: "BICYCLE_STORAGE",
    name_ca: "Guarda bicicletes",
    name_es: "Guarda bicicletas",
    name_en: "Bicycles storage",
  },
  {
    code: "BOARD_GAMES",
    name_ca: "Jocs de taula",
    name_es: "Juegos de mesa",
    name_en: "Board games",
  },
  {
    code: "CAPSULE_COFFEE_MAKER",
    name_ca: "Cafetera de càpsules",
    name_es: "Cafetera de cápsulas",
    name_en: "Capsule coffee maker",
  },
  {
    code: "CAR_NEEDED",
    name_ca: "Cotxe necessari",
    name_es: "Coche necesario",
    name_en: "Car needed",
  },
  {
    code: "CAR_NOT_NEEDED",
    name_ca: "Cotxe no necessari",
    name_es: "Coche no necesario",
    name_en: "Car not needed",
  },
  {
    code: "CAR_RECOMMENDED",
    name_ca: "Cotxe recomanat",
    name_es: "Coche recomendado",
    name_en: "Car recommended",
  },
  {
    code: "CLOTHES_HANGERS",
    name_ca: "Penjadors",
    name_es: "Percheros",
    name_en: "Hangers",
  },
  {
    code: "CLOTHES_LINE",
    name_ca: "Estenedor",
    name_es: "Tendedero",
    name_en: "Clothesline",
  },
  {
    code: "COFFEE_MAKER",
    name_ca: "Cafetera",
    name_es: "Cafetera",
    name_en: "Coffee maker",
  },
  {
    code: "COMMUNAL_SWIMMING_POOL",
    name_ca: "Piscina comunitària",
    name_es: "Piscina comunitaria",
    name_en: "Communal swimming pool",
  },
  {
    code: "COVERED_PARKING",
    name_ca: "Parking cobert",
    name_es: "Parking cubierto",
    name_en: "Covered parking",
  },
  {
    code: "COVERED_SWIMMING_POOL",
    name_ca: "Piscina coberta",
    name_es: "Piscina cubierta",
    name_en: "Indoor swimming pool",
  },
  {
    code: "DISHES",
    name_ca: "Vaixella",
    name_es: "Vajilla",
    name_en: "Crockery",
  },
  {
    code: "DISHWASHER",
    name_ca: "Rentavaixelles",
    name_es: "Lavavajillas",
    name_en: "Dishwasher",
  },
  {
    code: "DOLCEGUSTO",
    name_ca: "Cafetera DolceGusto",
    name_es: "Cafetera DolceGusto",
    name_en: "DolceGusto coffee maker",
  },
  {
    code: "DVD_PLAYER",
    name_ca: "Reproductor DVD",
    name_es: "Reproductor DVD",
    name_en: "DVD player",
  },
  {
    code: "ELEVATOR",
    name_ca: "Ascensor",
    name_es: "Ascensor",
    name_en: "Elevator",
  },
  {
    code: "FAN",
    name_ca: "Ventiladors",
    name_es: "Ventiladores",
    name_en: "Fans",
  },
  {
    code: "FARM_ANIMALS",
    name_ca: "Animals de granja",
    name_es: "Animales de granja",
    name_en: "Farm animals",
  },
  {
    code: "FIREPLACE",
    name_ca: "Xemeneia",
    name_es: "Chimenea",
    name_en: "Fireplace",
  },
  {
    code: "FIRE_EXTINGUISHER",
    name_ca: "Extintors",
    name_es: "Extintores",
    name_en: "Fire extinguishers",
  },
  {
    code: "FIRST_AID_KIT",
    name_ca: "Farmaciola",
    name_es: "Botiquín",
    name_en: "First aid kit",
  },
  {
    code: "FOOD_MIXER",
    name_ca: "Batedora",
    name_es: "Batidora",
    name_en: "Whisk",
  },
  {
    code: "FREEZER",
    name_ca: "Congelador",
    name_es: "Congelador",
    name_en: "Freezer",
  },
  {
    code: "FREE_PARKING",
    name_ca: "Parking gratuït",
    name_es: "Parking gratuito",
    name_en: "Free parking",
  },
  {
    code: "FREE_WIFI",
    name_ca: "WiFi gratuït",
    name_es: "WiFi gratuito",
    name_en: "Free WiFi",
  },
  {
    code: "FRIDGE",
    name_ca: "Gelera",
    name_es: "Frigorífico",
    name_en: "Fridge",
  },
  {
    code: "FRYING_PAN",
    name_ca: "Fregidora",
    name_es: "Freidora",
    name_en: "Fryer",
  },
  {
    code: "FURNISHED_TERRACE",
    name_ca: "Mobiliari terrassa",
    name_es: "Mobiliario terraza",
    name_en: "Terrace furniture",
  },
  {
    code: "FUTBOLIN",
    name_ca: "Futbolín",
    name_es: "Futbolín",
    name_en: "Futbolín",
  },
  {
    code: "GARDEN",
    name_ca: "Jardí",
    name_es: "Jardín",
    name_en: "Garden",
  },
  {
    code: "GYM",
    name_ca: "Gimnàs",
    name_es: "Gimnasio",
    name_en: "Gym",
  },
  {
    code: "HEATED_POOL",
    name_ca: "Piscina climatitzada",
    name_es: "Piscina climatizada",
    name_en: "Heated swimming pool",
  },
  {
    code: "HEATED_SWIMMING_POOL",
    name_ca: "Piscina climatitzada",
    name_es: "Piscina climatizada",
    name_en: "Heated swimming pool",
  },
  {
    code: "HEATING_CORRIDOR",
    name_ca: "Calefacció passadís",
    name_es: "Calefacción pasillo",
    name_en: "Hallway heating",
  },
  {
    code: "HEATING_KITCHEN",
    name_ca: "Calefacció cuina",
    name_es: "Calefacción cocina",
    name_en: "Kitchen heating",
  },
  {
    code: "HEATING_LIVING_ROOM",
    name_ca: "Calefacció sala d'estar",
    name_es: "Calefacción salón",
    name_en: "Living room heating",
  },
  {
    code: "HYDROMASSAGE_BATHTUB",
    name_ca: "Banyera hidromassatge",
    name_es: "Bañera hidromasaje",
    name_en: "Hot tub",
  },
  {
    code: "IRON",
    name_ca: "Planxa",
    name_es: "Plancha",
    name_en: "Iron",
  },
  {
    code: "IRONING_BOARD",
    name_ca: "Taula de planxar",
    name_es: "Tabla de planchar",
    name_en: "Ironing board",
  },
  {
    code: "JACUZZI",
    name_ca: "Jacuzzi",
    name_es: "Jacuzzi",
    name_en: "Jacuzzi",
  },
  {
    code: "KETTLE",
    name_ca: "Kettle",
    name_es: "Kettle",
    name_en: "Kettle",
  },
  {
    code: "KITCHEN",
    name_ca: "Cuina",
    name_es: "Cocina",
    name_en: "Kitchen",
  },
  {
    code: "MICROWAVE",
    name_ca: "Microones",
    name_es: "Microondas",
    name_en: "Microwave",
  },
  {
    code: "MINI_BAR",
    name_ca: "Mini bar",
    name_es: "Mini bar",
    name_en: "Mini bar",
  },
  {
    code: "NESPRESSO",
    name_ca: "Cafetera Nespresso",
    name_es: "Cafetera Nespresso",
    name_en: "Nespresso coffee machine",
  },
  {
    code: "ORCHARD",
    name_ca: "Hort",
    name_es: "Huerto",
    name_en: "Orchard",
  },
  {
    code: "OVEN",
    name_ca: "Forn",
    name_es: "Horno",
    name_en: "Oven",
  },
  {
    code: "PAID_PARKING",
    name_ca: "Parking de pagament",
    name_es: "Parking de pago",
    name_en: "Paid parking",
  },
  {
    code: "PAID_WIFI",
    name_ca: "WiFi de pagament",
    name_es: "WiFi de pago",
    name_en: "Paid WiFi",
  },
  {
    code: "PLAYGROUND",
    name_ca: "Zona infantil",
    name_es: "Zona infantil",
    name_en: "Children's area",
  },
  {
    code: "POOL_TABLE",
    name_ca: "Billar",
    name_es: "Billar",
    name_en: "Pool table",
  },
  {
    code: "PRIVATE_SWIMMING_POOL",
    name_ca: "Piscina privada",
    name_es: "Piscina privada",
    name_en: "Private swimming pool",
  },
  {
    code: "PUBLIC_SWIMMING_POOL",
    name_ca: "Piscina pública",
    name_es: "Piscina pública",
    name_en: "Public swimming pool",
  },
  {
    code: "SAFETY_BOX",
    name_ca: "Caixa forta",
    name_es: "Caja fuerte",
    name_en: "Safety box",
  },
  {
    code: "SATELLITE_TV",
    name_ca: "TV Satèl·lit",
    name_es: "TV Satélite",
    name_en: "Satellite TV",
  },
  {
    code: "SAUNA",
    name_ca: "Sauna",
    name_es: "Sauna",
    name_en: "Sauna",
  },
  {
    code: "SLIPPERS",
    name_ca: "Sabatilles",
    name_es: "Zapatillas",
    name_en: "Slippers",
  },
  {
    code: "SMART_TV",
    name_ca: "Smart TV",
    name_es: "Smart TV",
    name_en: "Smart TV",
  },
  {
    code: "SMOKE_DETECTOR",
    name_ca: "Detectors de fum",
    name_es: "Detectores de humo",
    name_en: "Smoke detectors",
  },
  {
    code: "SOLARIUM",
    name_ca: "Solarium",
    name_es: "Solarium",
    name_en: "Solarium",
  },
  {
    code: "SOUND_SYSTEM",
    name_ca: "Equip de música",
    name_es: "Equipo de música",
    name_en: "Music equipment",
  },
  {
    code: "SPICE_SELECTION",
    name_ca: "Espècies",
    name_es: "Especies",
    name_en: "Spices selection",
  },
  {
    code: "SQUEEZER",
    name_ca: "Espremedora",
    name_es: "Exprimidora",
    name_en: "Juicer",
  },
  {
    code: "STREET_PARKING",
    name_ca: "Parking al carrer",
    name_es: "Parking en la calle",
    name_en: "Parking on the street",
  },
  {
    code: "SUN_LOUNGERS",
    name_ca: "Hamaques",
    name_es: "Hamacas",
    name_en: "Hammocks",
  },
  {
    code: "TABLE_TENNIS",
    name_ca: "Ping-Pong",
    name_es: "Ping-Pong",
    name_en: "Ping pong",
  },
  {
    code: "TEAPOT",
    name_ca: "Tetera",
    name_es: "Tetera",
    name_en: "Teapot",
  },
  {
    code: "TERRACE",
    name_ca: "Terrassa",
    name_es: "Terraza",
    name_en: "Terrace",
  },
  {
    code: "TOASTER",
    name_ca: "Torradora",
    name_es: "Tostadora",
    name_en: "Toaster",
  },
  {
    code: "TOWELS",
    name_ca: "Tovalloles",
    name_es: "Toallas",
    name_en: "Towels",
  },
  {
    code: "TUMBLE_DRYER",
    name_ca: "Assecadora",
    name_es: "Secadora",
    name_en: "Tumble dryer",
  },
  {
    code: "TV",
    name_ca: "TV",
    name_es: "TV",
    name_en: "TV",
  },
  {
    code: "VACUUM",
    name_ca: "Aspirador",
    name_es: "Aspirador",
    name_en: "Vacuum cleaner",
  },
  {
    code: "WASHING_MACHINE",
    name_ca: "Rentadora",
    name_es: "Lavadora",
    name_en: "Washing machine",
  },
];
