<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <b-overlay
      :show="appLoading"
      variant="white"
      :opacity="0.95"
      no-wrap
      fixed
      z-index="10000"
    />

    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from "@core/components/scroll-to-top/ScrollToTop.vue";
import { BOverlay } from "bootstrap-vue";

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { localize } from "vee-validate";

import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  components: {
    // Layouts
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
    BOverlay,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return "layout-vertical";
    },
    appLoading() {
      return this.$store.getters["app/loading"];
    },
    urlLocale() {
      return this.$route?.query?.l || null;
    },
    mainTitle() {
      return this.$t("pages.mainTitle");
    },
    currentLanguage() {
      return this.$i18n.locale.substring(0, 2).toLowerCase();
    },
  },
  watch: {
    urlLocale() {
      this.seti18nLocale();
    },
    mainTitle(title) {
      document.title = title;
    },
    currentLanguage() {
      // ! we use nextTick to allow vee-validate to update the validation messages
      // TODO: Working but improvement needed
      this.$nextTick(() => {
        this.switchValidationLocale();
      });
    },
  },
  created() {
    this.seti18nLocale();
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  mounted() {
    document.title = this.mainTitle;
  },
  methods: {
    seti18nLocale() {
      if (!this.urlLocale) return;
      const locale = this.urlLocale.substring(0, 2);
      switch (locale) {
        case "ca":
          this.$i18n.locale = "ca-ES";
          break;
        case "es":
          this.$i18n.locale = "es-ES";
          break;
        default:
          this.$i18n.locale = "en-GB";
          break;
      }
    },
    switchValidationLocale() {
      localize(this.currentLanguage);
    },
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();
    const { enableScrollToTop } = $themeConfig.layout;

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
      enableScrollToTop,
    };
  },
};
</script>
