import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    owner: null,
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    owner(state) {
      return state.owner;
    },
    location(state) {
      return state.owner?.location || null;
    },
    bookings(state) {
      return state.owner?.bookings || [];
    },
    contracts(state) {
      return state.owner?.contracts || [];
    },
    accommodations(state, getters) {
      if (!getters.contracts.length) return [];
      const accommodations = [];

      getters.contracts.forEach((contract) => {
        if (!contract.accommodation) return;
        const isAccommodationAlreadyAdded = accommodations.find(
          (accommodation) =>
            accommodation["@id"] === contract.accommodation["@id"]
        );
        if (!isAccommodationAlreadyAdded)
          accommodations.push(contract.accommodation);
      });

      return accommodations;
    },
    currentAccommodations(state) {
      return state.owner?.currentAccommodations || [];
    },
    accommodationRates(state, getters) {
      if (!getters.contracts.length) return [];
      const rates = {};
      getters.contracts.forEach((contract) => {
        if (!contract.ownerRates?.length || !contract.accommodation) return;

        if (typeof rates[`${contract.accommodation.uuid}`] === "undefined") {
          rates[`${contract.accommodation.uuid}`] = [];
        }

        contract.ownerRates.forEach((ownerRate) => {
          rates[`${contract.accommodation.uuid}`].push(ownerRate);
        });
      });
      return rates;
    },
    reviews(state, getters) {
      if (!getters.bookings.length) return [];
      return getters.bookings
        .filter((booking) => !!booking.review)
        .map((booking) => ({
          ...booking.review,
          accommodation: {
            name: booking.accommodation?.name,
          },
          booking: {
            localizator: booking.localizator,
          },
        }))
        .filter((review) => !!review.reviewed)
        .sort((a, b) => new Date(b.postedAt) - new Date(a.postedAt));
    },
    rating(state, getters) {
      if (!getters.reviews.length) return null;

      let reviewsCount = 0;
      const calculatedRating = {
        facilities: 0,
        location: 0,
        cleaning: 0,
        communication: 0,
        checkin: 0,
        accuracy: 0,
      };

      getters.reviews.forEach((review) => {
        if (!review.averageRating) return;
        reviewsCount += 1;
        calculatedRating.facilities += review.rateFacilities || 0;
        calculatedRating.location += review.rateLocation || 0;
        calculatedRating.cleaning += review.rateCleaning || 0;
        calculatedRating.communication += review.rateCommunication || 0;
        calculatedRating.checkin += review.rateCheckin || 0;
        calculatedRating.accuracy += review.rateAccuracy || 0;
      });

      if (!reviewsCount) return null;
      return {
        facilities:
          Math.round((calculatedRating.facilities / reviewsCount) * 10) / 10,
        location:
          Math.round((calculatedRating.location / reviewsCount) * 10) / 10,
        cleaning:
          Math.round((calculatedRating.cleaning / reviewsCount) * 10) / 10,
        communication:
          Math.round((calculatedRating.communication / reviewsCount) * 10) / 10,
        checkin:
          Math.round((calculatedRating.checkin / reviewsCount) * 10) / 10,
        accuracy:
          Math.round((calculatedRating.accuracy / reviewsCount) * 10) / 10,
      };
    },
    averageRating(state, getters) {
      if (!getters.reviews.length) return null;
      let averageRatingSum = 0;
      let reviewsCount = 0;

      getters.reviews.forEach((review) => {
        if (!review.averageRating) return;
        reviewsCount += 1;
        averageRatingSum += review.averageRating;
      });

      if (!reviewsCount) return null;
      return Math.round((averageRatingSum / reviewsCount) * 10) / 10;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.owner = null;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_OWNER(state, payload) {
      state.owner = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchOwner({ commit }, ownerUuid) {
      return new Promise((resolve, reject) => {
        if (!ownerUuid)
          reject(new Error("The owner uuid is a required parameter"));
        commit("SET_LOADING", true);
        axios
          .get(`/owners/${ownerUuid}`)
          .then((response) => {
            commit("SET_OWNER", response.data);
            resolve();
          })
          .catch((error) => {
            // TODO: log the error with Sentry
            reject(error);
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
  },
};
