export default [
  //    - Accommodations list
  {
    path: "/accommodations",
    name: "foravila-accommodations-list",
    component: () =>
      import("@/views/accommodations/list/AccommodationsList.vue"),
    meta: {
      pageTitle: "Accommodations list",
      breadcrumb: [{ text: "Accommodations", active: true }],
    },
  },
  //    - Accommodation view
  {
    path: "/accommodations/:slug",
    name: "foravila-accommodation-view",
    component: () =>
      import("@/views/accommodations/accommodation/AccommodationView.vue"),
    meta: {
      pageTitle: "Accommodation details",
      breadcrumb: [
        {
          text: "Accommodations",
          to: { name: "foravila-accommodations-list" },
        },
        { text: "Accommodation", active: true },
      ],
    },
  },
];
