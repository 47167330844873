import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    accommodation: null,
    loadingPictures: false,
    pictures: [],
    loadingBedrooms: false,
    bedrooms: [],
    loadingBathrooms: false,
    bathrooms: [],
    loadingAmenities: false,
    amenities: [],
    loadingServices: false,
    services: [],
    loadingReviews: false,
    reviews: [],
    loadingAvailabilities: false,
    availabilities: [],
    loadingRates: false,
    rates: [],
    loadingBookings: false,
    bookings: [],
    loadingDatesLocks: false,
    datesLocks: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    accommodation(state) {
      return state.accommodation;
    },
    location(state) {
      return state.accommodation?.location || null;
    },
    loadingPictures(state) {
      return state.loadingPictures;
    },
    pictures(state) {
      return state.pictures;
    },
    loadingBedrooms(state) {
      return state.loadingBedrooms;
    },
    bedrooms(state) {
      return state.bedrooms;
    },
    loadingBathrooms(state) {
      return state.loadingBathrooms;
    },
    bathrooms(state) {
      return state.bathrooms;
    },
    loadingBedroomsOrBathrooms(state, getters) {
      return getters.loadingBedrooms || getters.loadingBathrooms;
    },
    loadingAmenities(state) {
      return state.loadingAmenities;
    },
    amenities(state) {
      return state.amenities;
    },
    loadingServices(state) {
      return state.loadingServices;
    },
    services(state) {
      return state.services;
    },
    ownerManagedServices(state, getters) {
      if (!getters.services.length) return [];
      return getters.services.filter(
        (service) => !service.chargable && service.paymentTime !== "PROVIDER"
      );
    },
    agencyManagedServices(state, getters) {
      if (!getters.services.length) return [];
      return getters.services.filter(
        (service) => service.chargable && service.paymentTime !== "PROVIDER"
      );
    },
    loadingReviews(state) {
      return state.loadingReviews;
    },
    reviews(state) {
      return state.reviews;
    },
    rating(state, getters) {
      if (!getters.reviews.length) return null;

      let reviewsCount = 0;
      const calculatedRating = {
        facilities: 0,
        location: 0,
        cleaning: 0,
        communication: 0,
        checkin: 0,
        accuracy: 0,
      };

      getters.reviews.forEach((review) => {
        if (!review.averageRating) return;
        reviewsCount += 1;
        calculatedRating.facilities += review.rateFacilities || 0;
        calculatedRating.location += review.rateLocation || 0;
        calculatedRating.cleaning += review.rateCleaning || 0;
        calculatedRating.communication += review.rateCommunication || 0;
        calculatedRating.checkin += review.rateCheckin || 0;
        calculatedRating.accuracy += review.rateAccuracy || 0;
      });

      if (!reviewsCount) return null;
      return {
        facilities:
          Math.round((calculatedRating.facilities / reviewsCount) * 10) / 10,
        location:
          Math.round((calculatedRating.location / reviewsCount) * 10) / 10,
        cleaning:
          Math.round((calculatedRating.cleaning / reviewsCount) * 10) / 10,
        communication:
          Math.round((calculatedRating.communication / reviewsCount) * 10) / 10,
        checkin:
          Math.round((calculatedRating.checkin / reviewsCount) * 10) / 10,
        accuracy:
          Math.round((calculatedRating.accuracy / reviewsCount) * 10) / 10,
      };
    },
    averageRating(state, getters) {
      if (!getters.reviews.length) return null;
      let averageRatingSum = 0;
      let reviewsCount = 0;

      getters.reviews.forEach((review) => {
        if (!review.averageRating) return;
        reviewsCount += 1;
        averageRatingSum += review.averageRating;
      });

      if (!reviewsCount) return null;
      return Math.round((averageRatingSum / reviewsCount) * 10) / 10;
    },
    loadingAvailabilities(state) {
      return state.loadingAvailabilities;
    },
    availabilities(state) {
      return state.availabilities;
    },
    loadingRates(state) {
      return state.loadingRates;
    },
    rates(state) {
      return state.rates;
    },
    availabilitiesAndRates(state, getters) {
      const availabilitiesAndRates = [];
      getters.availabilities.forEach((availability) => {
        getters.rates.forEach((rate) => {
          if (rate.date === availability.date) {
            availabilitiesAndRates.push({
              date: availability?.date || null,
              available: availability?.available || null,
              rate: {
                price: rate?.price || null,
                minimumStay: rate?.minimumStay || null,
                checkinAvailable: rate?.checkinAvailable || null,
                checkoutAvailable: rate?.checkoutAvailable || null,
              },
            });
          }
        });
      });
      return availabilitiesAndRates;
    },
    loadingBookings(state) {
      return state.loadingBookings;
    },
    bookings(state) {
      return state.bookings;
    },
    loadingDatesLocks(state) {
      return state.loadingDatesLocks;
    },
    datesLocks(state) {
      return state.datesLocks;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.accommodation = null;
      state.loadingPictures = false;
      state.pictures = [];
      state.loadingBedrooms = false;
      state.bedrooms = [];
      state.loadingBathrooms = false;
      state.bathrooms = [];
      state.loadingAmenities = false;
      state.amenities = [];
      state.loadingServices = false;
      state.services = [];
      state.loadingReviews = false;
      state.reviews = [];
      state.loadingAvailabilities = false;
      state.availabilities = [];
      state.loadingRates = false;
      state.rates = [];
      state.loadingBookings = false;
      state.bookings = [];
      state.loadingDatesLocks = false;
      state.datesLocks = [];
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_ACCOMMODATION(state, payload) {
      state.accommodation = payload;
    },
    SET_LOADING_PICTURES(state, payload) {
      state.loadingPictures = payload;
    },
    SET_PICTURES(state, payload) {
      state.pictures = payload;
    },
    SET_LOADING_BEDROOMS(state, payload) {
      state.loadingBedrooms = payload;
    },
    SET_BEDROOMS(state, payload) {
      state.bedrooms = payload;
    },
    SET_LOADING_BATHROOMS(state, payload) {
      state.loadingBathrooms = payload;
    },
    SET_BATHROOMS(state, payload) {
      state.bathrooms = payload;
    },
    SET_LOADING_AMENITIES(state, payload) {
      state.loadingAmenities = payload;
    },
    SET_AMENITIES(state, payload) {
      state.amenities = payload;
    },
    SET_LOADING_SERVICES(state, payload) {
      state.loadingServices = payload;
    },
    SET_SERVICES(state, payload) {
      state.services = payload;
    },
    SET_LOADING_REVIEWS(state, payload) {
      state.loadingReviews = payload;
    },
    SET_REVIEWS(state, payload) {
      state.reviews = payload;
    },
    SET_LOADING_AVAILABILITIES(state, payload) {
      state.loadingAvailabilities = payload;
    },
    SET_AVAILABILITIES(state, payload) {
      state.availabilities = payload;
    },
    SET_LOADING_RATES(state, payload) {
      state.loadingRates = payload;
    },
    SET_RATES(state, payload) {
      state.rates = payload;
    },
    SET_LOADING_BOOKINGS(state, payload) {
      state.loadingBookings = payload;
    },
    SET_BOOKINGS(state, payload) {
      state.bookings = payload;
    },
    SET_LOADING_DATES_LOCKS(state, payload) {
      state.loadingDatesLocks = payload;
    },
    SET_DATES_LOCKS(state, payload) {
      state.datesLocks = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    async fetchAccommodationBySlug({ commit }, slug) {
      if (!slug) return new Error("The slug is null or empty");

      commit("SET_LOADING", true);

      const accommodationUri = await axios
        .get(`/accommodations?slug=${slug}`)
        .then((response) => {
          if (response.data["hydra:totalItems"] > 0)
            return response.data["hydra:member"][0]["@id"];
          return null;
        })
        .catch(() => null);

      return new Promise((resolve, reject) => {
        if (!accommodationUri) {
          commit("SET_LOADING", false);
          reject(
            new Error("The accommodation for the given slug does not exist")
          );
          return;
        }

        axios
          .get(accommodationUri)
          .then((response) => {
            commit("SET_ACCOMMODATION", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            // TODO: Log error in Sentry
            reject(error);
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchAccommodation({ commit }, accommodationUuid) {
      if (!accommodationUuid)
        return new Error("The accommodationUuid is null or empty");

      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true);

        axios
          .get(`/accommodations/${accommodationUuid}`)
          .then((response) => {
            commit("SET_ACCOMMODATION", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            // TODO: Log error in Sentry
            reject(error);
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
    fetchPictures({ commit }, accommodationUuid) {
      if (!accommodationUuid)
        return new Error("The accommodation uuid is null or empty");

      return new Promise((resolve, reject) => {
        commit("SET_LOADING_PICTURES", true);
        axios
          .get(`/accommodations/${accommodationUuid}/pictures?pagination=false`)
          .then((response) => {
            commit("SET_PICTURES", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            // TODO: Log error in Sentry
            reject(error);
          })
          .finally(() => commit("SET_LOADING_PICTURES", false));
      });
    },
    fetchBedrooms({ commit }, accommodationUuid) {
      if (!accommodationUuid)
        return new Error("The accommodation uuid is null or empty");

      return new Promise((resolve, reject) => {
        commit("SET_LOADING_BEDROOMS", true);
        axios
          .get(`/accommodations/${accommodationUuid}/bedrooms?pagination=false`)
          .then((response) => {
            commit("SET_BEDROOMS", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            // TODO: Log error in Sentry
            reject(error);
          })
          .finally(() => commit("SET_LOADING_BEDROOMS", false));
      });
    },
    fetchBathrooms({ commit }, accommodationUuid) {
      if (!accommodationUuid)
        return new Error("The accommodation uuid is null or empty");

      return new Promise((resolve, reject) => {
        commit("SET_LOADING_BATHROOMS", true);
        axios
          .get(
            `/accommodations/${accommodationUuid}/bathrooms?pagination=false`
          )
          .then((response) => {
            commit("SET_BATHROOMS", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            // TODO: Log error in Sentry
            reject(error);
          })
          .finally(() => commit("SET_LOADING_BATHROOMS", false));
      });
    },
    fetchAmenities({ commit }, accommodationUuid) {
      if (!accommodationUuid)
        return new Error("The accommodation uuid is null or empty");

      return new Promise((resolve, reject) => {
        commit("SET_LOADING_AMENITIES", true);
        axios
          .get(
            `/accommodations/${accommodationUuid}/amenities?pagination=false`
          )
          .then((response) => {
            commit("SET_AMENITIES", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            // TODO: Log error in Sentry
            reject(error);
          })
          .finally(() => commit("SET_LOADING_AMENITIES", false));
      });
    },
    fetchServices({ commit }, accommodationUuid) {
      if (!accommodationUuid)
        return new Error("The accommodation uuid is null or empty");

      return new Promise((resolve, reject) => {
        commit("SET_LOADING_SERVICES", true);
        axios
          .get(`/accommodations/${accommodationUuid}/services?pagination=false`)
          .then((response) => {
            commit("SET_SERVICES", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            // TODO: Log error in Sentry
            reject(error);
          })
          .finally(() => commit("SET_LOADING_SERVICES", false));
      });
    },
    fetchReviews({ commit }, accommodationUuid) {
      if (!accommodationUuid)
        return new Error("The accommodation uuid is null or empty");

      return new Promise((resolve, reject) => {
        commit("SET_LOADING_REVIEWS", true);
        axios
          .get(`/accommodations/${accommodationUuid}/reviews?pagination=false`)
          .then((response) => {
            commit("SET_REVIEWS", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            // TODO: Log error in Sentry
            reject(error);
          })
          .finally(() => commit("SET_LOADING_REVIEWS", false));
      });
    },
    fetchAvailabilities({ commit }, accommodationUuid) {
      if (!accommodationUuid)
        return new Error("The accommodation uuid is null or empty");

      return new Promise((resolve, reject) => {
        commit("SET_LOADING_AVAILABILITIES", true);
        axios
          .get(
            `/accommodations/${accommodationUuid}/availabilities?pagination=false&order[date]=asc`
          )
          .then((response) => {
            commit("SET_AVAILABILITIES", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            // TODO: Log error in Sentry
            reject(error);
          })
          .finally(() => commit("SET_LOADING_AVAILABILITIES", false));
      });
    },
    fetchRates({ commit }, accommodationUuid) {
      if (!accommodationUuid)
        return new Error("The accommodation uuid is null or empty");

      return new Promise((resolve, reject) => {
        commit("SET_LOADING_RATES", true);
        axios
          .get(`/accommodations/${accommodationUuid}/rates?pagination=false`)
          .then((response) => {
            commit("SET_RATES", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            // TODO: Log error in Sentry
            reject(error);
          })
          .finally(() => commit("SET_LOADING_RATES", false));
      });
    },
    fetchBookings({ commit }, accommodationUuid) {
      if (!accommodationUuid)
        return new Error("The accommodation uuid is null or empty");

      return new Promise((resolve, reject) => {
        commit("SET_LOADING_BOOKINGS", true);
        axios
          .get(`/accommodations/${accommodationUuid}/bookings?pagination=false`)
          .then((response) => {
            commit("SET_BOOKINGS", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            // TODO: Log error in Sentry
            reject(error);
          })
          .finally(() => commit("SET_LOADING_BOOKINGS", false));
      });
    },
    fetchDatesLocks({ commit }, accommodationUuid) {
      if (!accommodationUuid)
        return new Error("The accommodation uuid is null or empty");

      return new Promise((resolve, reject) => {
        commit("SET_LOADING_DATES_LOCKS", true);
        axios
          .get(
            `/accommodations/${accommodationUuid}/dates_locks?pagination=false`
          )
          .then((response) => {
            commit("SET_DATES_LOCKS", response.data["hydra:member"]);
            resolve(response);
          })
          .catch((error) => {
            // TODO: Log error in Sentry
            reject(error);
          })
          .finally(() => commit("SET_LOADING_DATES_LOCKS", false));
      });
    },
  },
};
