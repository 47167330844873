export default [
  //    - Bookings list
  {
    path: "/bookings/list",
    name: "foravila-bookings-list",
    component: () => import("@/views/bookings/list/BookingsList.vue"),
    meta: {
      pageTitle: "Bookings list",
      breadcrumb: [{ text: "Bookings" }, { text: "List", active: true }],
    },
  },
  //    - Bookings calendar
  {
    path: "/bookings/calendar",
    name: "foravila-bookings-calendar",
    component: () => import("@/views/bookings/calendar/BookingsCalendar.vue"),
    meta: {
      pageTitle: "Availability calendar",
      breadcrumb: [{ text: "Bookings" }, { text: "Calendar", active: true }],
    },
  },
  //    - Booking view
  {
    path: "/bookings/:localizator",
    name: "foravila-booking-view",
    component: () => import("@/views/bookings/booking/BookingView.vue"),
    meta: {
      pageTitle: "Booking details",
      breadcrumb: [
        { text: "Bookings", to: { name: "foravila-bookings-list" } },
        { text: "Booking", active: true },
      ],
    },
  },
];
