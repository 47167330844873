import Vue from "vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import countries from "@/utils/countries";
import amenities from "@/utils/amenities";
import { UNCALCULABLE_FREQUENCIES } from "@/utils/consts";
import { formatCurrency } from "@/utils/formatters";

/** **************** */
/** **************** */
/* PRIVATE FUNCTIONS */
/** **************** */
/** **************** */

function calculateBookingServiceOwnerBasePrice(bookingService) {
  // If the cost price is not defined, return the pvp price by default
  let basePrice = bookingService.pvpPrice;
  if (bookingService.costPrice || bookingService.costPrice === 0) {
    basePrice = bookingService.costPrice;
  }
  return basePrice;
}

function simplifyLocale(locale) {
  if (!locale) return null;
  return locale.split("-")[0].toLowerCase();
}

/** ****** */
/** ****** */
/* EXPORTS */
/** ****** */
/** ****** */

export const isEmpty = (data) => {
  if (typeof data === "string") {
    return data.trim().length === 0;
  }
  if (typeof data === "number" || typeof data === "boolean") {
    return false;
  }
  if (typeof data === "undefined" || data === null) {
    return true;
  }
  if (typeof data.length !== "undefined") {
    return data.length === 0;
  }

  // let count = 0
  // for (const i in data) {
  //   if (data.hasOwnProperty(i)) {
  //     count++
  //   }
  // }
  // return count == 0
  return false;
};

export const notifyError = (title, text) => {
  Vue.$toast(
    {
      component: ToastificationContent,
      props: {
        title,
        icon: "AlertTriangleIcon",
        text,
        variant: "danger",
      },
    },
    {
      timeout: 6000,
    }
  );
};

export const notifySuccess = (title, text) => {
  Vue.$toast(
    {
      component: ToastificationContent,
      props: {
        title,
        icon: "CheckCircleIcon",
        text,
        variant: "success",
      },
    },
    {
      timeout: 6000,
    }
  );
};

export const getCountryName = (countryCode, locale = "ca") => {
  if (!countryCode) return null;

  const country = countries.find((c) => c.code === countryCode);
  if (country) {
    switch (simplifyLocale(locale)) {
      case "ca":
        return country.name_ca;
      case "es":
        return country.name_es;
      case "en":
      default:
        return country.name_en;
    }
  }
  return countryCode;
};

export const getAccommodationTypeName = (
  accommodationTypeCode,
  locale = "ca-ES"
) => {
  if (!accommodationTypeCode) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      switch (accommodationTypeCode.toUpperCase()) {
        case "VILLA":
          return "Villa";
        case "CHALET":
          return "Xalet";
        case "TOWN_HOUSE":
          return "Casa de poble";
        case "COUNTRY_HOUSE":
          return "Casa de camp";
        case "APARTMENT":
          return "Apartament";
        case "PLOT":
          return "Solar";
        case "BUILDABLE_RUSTIC_LAND":
          return "Finca rústica (edificable)";
        case "NON_BUILDABLE_RUSTIC_LAND":
          return "Finca rústica (no edificable)";
        case "OTHER":
          return "Altre";
        default:
          return "Allotjament";
      }
    case "es":
      switch (accommodationTypeCode.toUpperCase()) {
        case "VILLA":
          return "Villa";
        case "CHALET":
          return "Chalet";
        case "TOWN_HOUSE":
          return "Casa de pueblo";
        case "COUNTRY_HOUSE":
          return "Casa de campo";
        case "APARTMENT":
          return "Apartamento";
        case "PLOT":
          return "Solar";
        case "BUILDABLE_RUSTIC_LAND":
          return "Finca rústica (edificable)";
        case "NON_BUILDABLE_RUSTIC_LAND":
          return "Finca rústica (no edificable)";
        case "OTHER":
          return "Otro";
        default:
          return "Alojamiento";
      }
    case "en":
    default:
      switch (accommodationTypeCode.toUpperCase()) {
        case "VILLA":
          return "Villa";
        case "CHALET":
          return "Chalet";
        case "TOWN_HOUSE":
          return "Town house";
        case "COUNTRY_HOUSE":
          return "Country house";
        case "APARTMENT":
          return "Apartment";
        case "PLOT":
          return "Plot";
        case "BUILDABLE_RUSTIC_LAND":
          return "Rustic property (buildable)";
        case "NON_BUILDABLE_RUSTIC_LAND":
          return "Rustic property (not buildable)";
        case "OTHER":
          return "Other";
        default:
          return "Accommodation";
      }
  }
};

export const getAddressTypeName = (addressTypeCode) => {
  if (!addressTypeCode) return null;
  switch (addressTypeCode) {
    case "STREET":
      return "Carrer";
    case "AVENUE":
      return "Avinguda";
    case "PATH":
    case "PATHWAY":
      return "Camí";
    case "ROAD":
      return "Carretera";
    case "DISSEMINATED":
      return "Disseminat";
    case "WAY":
      return "Via";
    case "FREEWAY":
      return "Autovia";
    case "SQUARE":
      return "Plaça";
    case "BOULEVARD":
      return "Boulevard";
    case "COURT":
      return "Pati";
    case "PASSAGE":
      return "Passatge";
    case "PROMENADE":
      return "Passeig";
    case "RAMBLA":
      return "Rambla";
    case "OTHER":
      return "Altres";
    default:
      return addressTypeCode;
  }
};

export const getFullAddress = (
  location,
  locale = "ca-ES",
  city = true,
  region = true,
  country = true
) => {
  if (!location) return null;

  const address = [];

  if (
    (location.addressType && location.addressType !== "DISSEMINATED") ||
    location.addressName
  ) {
    const addressName = [];
    if (!isEmpty(location.addressType)) {
      addressName.push(getAddressTypeName(location.addressType));
    }
    if (!isEmpty(location.addressName)) {
      addressName.push(location.addressName);
    }
    if (!isEmpty(location.addressNumber)) {
      addressName.push(location.addressNumber);
    }
    if (addressName.length > 0) address.push(addressName.join(" "));
  }

  switch (locale.toLowerCase()) {
    case "ca-es":
      if (!isEmpty(location.addressBlock)) {
        address.push(`Bloc ${location.addressBlock}`);
      }
      if (!isEmpty(location.addressPortal)) {
        address.push(`Portal ${location.addressPortal}`);
      }
      if (!isEmpty(location.addressStairs)) {
        address.push(`Escala ${location.addressStairs}`);
      }
      if (!isEmpty(location.addressFloor)) {
        address.push(`Pis ${location.addressFloor}`);
      }
      if (!isEmpty(location.addressDoor)) {
        address.push(`Porta ${location.addressDoor}`);
      }
      if (!isEmpty(location.addressPolygon)) {
        address.push(`Polígon ${location.addressPolygon}`);
      }
      if (!isEmpty(location.addressPlot)) {
        address.push(`Parcel·la ${location.addressPlot}`);
      }
      break;
    case "es-es":
      if (!isEmpty(location.addressBlock)) {
        address.push(`Bloque ${location.addressBlock}`);
      }
      if (!isEmpty(location.addressPortal)) {
        address.push(`Portal ${location.addressPortal}`);
      }
      if (!isEmpty(location.addressStairs)) {
        address.push(`Escalera ${location.addressStairs}`);
      }
      if (!isEmpty(location.addressFloor)) {
        address.push(`Piso ${location.addressFloor}`);
      }
      if (!isEmpty(location.addressDoor)) {
        address.push(`Puerta ${location.addressDoor}`);
      }
      if (!isEmpty(location.addressPolygon)) {
        address.push(`Polígono ${location.addressPolygon}`);
      }
      if (!isEmpty(location.addressPlot)) {
        address.push(`Parcela ${location.addressPlot}`);
      }
      break;
    case "en-gb":
    default:
      if (!isEmpty(location.addressBlock)) {
        address.push(`Block ${location.addressBlock}`);
      }
      if (!isEmpty(location.addressPortal)) {
        address.push(`Portal ${location.addressPortal}`);
      }
      if (!isEmpty(location.addressStairs)) {
        address.push(`Stairs ${location.addressStairs}`);
      }
      if (!isEmpty(location.addressFloor)) {
        address.push(`Floor ${location.addressFloor}`);
      }
      if (!isEmpty(location.addressDoor)) {
        address.push(`Door ${location.addressDoor}`);
      }
      if (!isEmpty(location.addressPolygon)) {
        address.push(`Polygon ${location.addressPolygon}`);
      }
      if (!isEmpty(location.addressPlot)) {
        address.push(`Plot ${location.addressPlot}`);
      }
      break;
  }

  if (city && (location.zip || location.city)) {
    const addressCity = [];
    if (!isEmpty(location.zip)) addressCity.push(location.zip);
    if (!isEmpty(location.city)) addressCity.push(location.city);
    if (addressCity.length > 0) address.push(addressCity.join(" "));
  }

  if (region && !isEmpty(location.region)) address.push(location.region);
  if (country && !isEmpty(location.country)) {
    address.push(getCountryName(location.country, locale));
  }

  return address ? address.join(", ") : null;
};

export const getViewName = (viewCode, locale = "ca-ES") => {
  if (!viewCode) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      switch (viewCode) {
        case "NONE":
          return "Sense vistes";
        case "GARDEN":
          return "Jardí";
        case "POOL":
          return "Piscina";
        case "BEACH":
          return "Platja";
        case "OCEAN":
          return "Mar";
        case "MOUNTAIN":
          return "Muntanya";
        case "TERRACE":
          return "Terrassa";
        case "STREET":
          return "Carrer";
        default:
          return viewCode;
      }
    case "es":
      switch (viewCode) {
        case "NONE":
          return "Sin vistas";
        case "GARDEN":
          return "Jardín";
        case "POOL":
          return "Piscina";
        case "BEACH":
          return "Playa";
        case "OCEAN":
          return "Mar";
        case "MOUNTAIN":
          return "Montaña";
        case "TERRACE":
          return "Terraza";
        case "STREET":
          return "Calle";
        default:
          return viewCode;
      }
    case "en":
    default:
      switch (viewCode) {
        case "NONE":
          return "No views";
        case "GARDEN":
          return "Garden";
        case "POOL":
          return "Swimming pool";
        case "BEACH":
          return "Beach";
        case "OCEAN":
          return "Ocean";
        case "MOUNTAIN":
          return "Mountain";
        case "TERRACE":
          return "Terrace";
        case "STREET":
          return "Street";
        default:
          return viewCode;
      }
  }
};

export const getHeatingName = (heatingCode, locale = "ca-ES") => {
  if (!heatingCode) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      switch (heatingCode) {
        case "AIR_CONDITIONING":
          return "Sí, aire acondicionat";
        case "ELECTRIC_RADIATORS":
          return "Sí, radiadors elèctrics";
        case "OIL_RADIATORS":
          return "Sí, radiadors d'oli";
        case "WATER_RADIATORS":
          return "Sí, radiadors d'aigua";
        case "FLOOR":
          return "Sí, sòl radiant";
        case "MIXED":
          return "Sí, mixte";
        default:
          return heatingCode;
      }
    case "es":
      switch (heatingCode) {
        case "AIR_CONDITIONING":
          return "Sí, aire acondicionado";
        case "ELECTRIC_RADIATORS":
          return "Sí, radiadores eléctricos";
        case "OIL_RADIATORS":
          return "Sí, radiadores de aceite";
        case "WATER_RADIATORS":
          return "Sí, radiadores de agua";
        case "FLOOR":
          return "Sí, suelo radiante";
        case "MIXED":
          return "Sí, mixto";
        default:
          return heatingCode;
      }
    case "en":
    default:
      switch (heatingCode) {
        case "AIR_CONDITIONING":
          return "Yes, A/C";
        case "ELECTRIC_RADIATORS":
          return "Yes, electric radiators";
        case "OIL_RADIATORS":
          return "Yes, oil radiators";
        case "WATER_RADIATORS":
          return "Yes, water radiators";
        case "FLOOR":
          return "Yes, underfloor heating";
        case "MIXED":
          return "Yes, mixed";
        default:
          return heatingCode;
      }
  }
};

export const getBedTypeName = (bedTypeCode, locale = "ca-ES") => {
  if (!bedTypeCode) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      switch (bedTypeCode) {
        case "SINGLE":
          return "Llit individual";
        case "DOUBLE":
          return "Llit doble";
        case "SINGLE_BUNK_BED":
          return "Llitera individual";
        case "DOUBLE_BUNK_BED":
          return "Llitera doble";
        case "MIXED_BUNK_BED":
          return "Llitera mixta";
        case "SINGLE_SOFA_BED":
          return "Sofà llit individual";
        case "DOUBLE_SOFA_BED":
          return "Sofà llit doble";
        default:
          return bedTypeCode;
      }
    case "es":
      switch (bedTypeCode) {
        case "SINGLE":
          return "Cama individual";
        case "DOUBLE":
          return "Cama doble";
        case "SINGLE_BUNK_BED":
          return "Litera individual";
        case "DOUBLE_BUNK_BED":
          return "Litera doble";
        case "MIXED_BUNK_BED":
          return "Litera mixta";
        case "SINGLE_SOFA_BED":
          return "Sofá cama individual";
        case "DOUBLE_SOFA_BED":
          return "Sofá cama doble";
        default:
          return bedTypeCode;
      }
    case "en":
    default:
      switch (bedTypeCode) {
        case "SINGLE":
          return "Single bed";
        case "DOUBLE":
          return "Double bed";
        case "SINGLE_BUNK_BED":
          return "Single bunk bed";
        case "DOUBLE_BUNK_BED":
          return "Double bunk bed";
        case "MIXED_BUNK_BED":
          return "Mixed bunk bed";
        case "SINGLE_SOFA_BED":
          return "Single sofa bed";
        case "DOUBLE_SOFA_BED":
          return "Double sofa bed";
        default:
          return bedTypeCode;
      }
  }
};

export const getMattressTypeName = (mattressTypeCode, locale = "ca-ES") => {
  if (!mattressTypeCode) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      switch (mattressTypeCode) {
        case "SPRINGS":
          return "Matalàs de molles";
        case "FOAM":
          return "Matalàs d'escuma";
        case "LATEX":
          return "Matalàs de latex";
        case "WATER":
          return "Matalàs d'aigua";
        case "AIR":
          return "Matalàs d'aire";
        case "MIXED":
          return "Matalàs mixte";
        default:
          return mattressTypeCode;
      }
    case "es":
      switch (mattressTypeCode) {
        case "SPRINGS":
          return "Colchón de muelles";
        case "FOAM":
          return "Colchón de espuma";
        case "LATEX":
          return "Colchón de latex";
        case "WATER":
          return "Colchón de agua";
        case "AIR":
          return "Colchón de aire";
        case "MIXED":
          return "Colchón mixto";
        default:
          return mattressTypeCode;
      }
    case "en":
    default:
      switch (mattressTypeCode) {
        case "SPRINGS":
          return "Spring mattress";
        case "FOAM":
          return "Foam mattress";
        case "LATEX":
          return "Latex mattress";
        case "WATER":
          return "Water mattress";
        case "AIR":
          return "Air mattress";
        case "MIXED":
          return "Mixed mattress";
        default:
          return mattressTypeCode;
      }
  }
};

export const getPillowTypeName = (pillowTypeCode, locale = "ca-ES") => {
  if (!pillowTypeCode) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      switch (pillowTypeCode) {
        case "FOAM":
          return "Coixí d'escuma";
        case "LATEX":
          return "Coixí de latex";
        case "FEATHERS":
          return "Coixí de plomes";
        default:
          return pillowTypeCode;
      }
    case "es":
      switch (pillowTypeCode) {
        case "FOAM":
          return "Cojín de espuma";
        case "LATEX":
          return "Cojín de latex";
        case "FEATHERS":
          return "Cojín de plumas";
        default:
          return pillowTypeCode;
      }
    case "en":
    default:
      switch (pillowTypeCode) {
        case "FOAM":
          return "Foam pillow";
        case "LATEX":
          return "Latex pillow";
        case "FEATHERS":
          return "Feather pillow";
        default:
          return pillowTypeCode;
      }
  }
};

export const getBedDescriptionText = (bed, locale = "ca-ES") => {
  const text = [];
  if (bed.width && bed.length) {
    text.push(`${bed.width} x ${bed.length} cm`);
  }
  if (bed.mattressType) {
    text.push(getMattressTypeName(bed.mattressType, locale));
  }
  if (bed.pillowType) {
    text.push(getPillowTypeName(bed.pillowType, locale));
  }
  return text.join(" · ");
};

export const getBedroomTypeName = (bedroomTypeCode, locale = "ca-ES") => {
  if (!bedroomTypeCode) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      switch (bedroomTypeCode) {
        case "MASTER":
          return "Principal";
        case "STANDARD":
          return "Estàndard";
        case "COMUNAL":
          return "Zona comuna";
        case "OPEN":
          return "Obert";
        default:
          return bedroomTypeCode;
      }
    case "es":
      switch (bedroomTypeCode) {
        case "MASTER":
          return "Principal";
        case "STANDARD":
          return "Estándar";
        case "COMUNAL":
          return "Zona común";
        case "OPEN":
          return "Abierto";
        default:
          return bedroomTypeCode;
      }
    case "en":
    default:
      switch (bedroomTypeCode) {
        case "MASTER":
          return "Master";
        case "STANDARD":
          return "Standard";
        case "COMUNAL":
          return "Common area";
        case "OPEN":
          return "Open";
        default:
          return bedroomTypeCode;
      }
  }
};

export const getBedroomAmenitiesText = (bedroom, locale = "ca-ES") => {
  if (!bedroom) return null;

  const text = [];
  switch (simplifyLocale(locale)) {
    case "ca":
      if (bedroom.wardrobe) text.push("Armari");
      if (bedroom.tv) text.push("TV");
      if (bedroom.smartTv) text.push("Smart TV");
      if (bedroom.blinds) text.push("Persianes");
      if (bedroom.curtains) text.push("Cortines");
      if (bedroom.balcony) text.push("Balcó");
      if (bedroom.mosquitoNet) text.push("Mosquitera");
      if (bedroom.wifiCoverage) {
        if (bedroom.wifiConnectionSpeed)
          text.push(`Cobertura WiFi (${bedroom.wifiConnectionSpeed} Mb/s)`);
        else text.push("Cobertura WiFi");
      }
      if (bedroom.noiseLevel)
        text.push(`Renou ambiental (${bedroom.noiseLevel} dB)`);
      if (bedroom.disabledAdapted) text.push("Adaptat");
      break;
    case "es":
      if (bedroom.wardrobe) text.push("Armario");
      if (bedroom.tv) text.push("TV");
      if (bedroom.smartTv) text.push("Smart TV");
      if (bedroom.blinds) text.push("Persianas");
      if (bedroom.curtains) text.push("Cortinas");
      if (bedroom.balcony) text.push("Balcón");
      if (bedroom.mosquitoNet) text.push("Mosquitera");
      if (bedroom.wifiCoverage) {
        if (bedroom.wifiConnectionSpeed)
          text.push(`Cobertura WiFi (${bedroom.wifiConnectionSpeed} Mb/s)`);
        else text.push("Cobertura WiFi");
      }
      if (bedroom.noiseLevel)
        text.push(`Ruido ambiental (${bedroom.noiseLevel} dB)`);
      if (bedroom.disabledAdapted) text.push("Adaptado");
      break;
    case "en":
    default:
      if (bedroom.wardrobe) text.push("Wardrobe");
      if (bedroom.tv) text.push("TV");
      if (bedroom.smartTv) text.push("Smart TV");
      if (bedroom.blinds) text.push("Blinds");
      if (bedroom.curtains) text.push("Curtains");
      if (bedroom.balcony) text.push("Balcony");
      if (bedroom.mosquitoNet) text.push("Mosquito net");
      if (bedroom.wifiCoverage) {
        if (bedroom.wifiConnectionSpeed)
          text.push(`WiFi coverage (${bedroom.wifiConnectionSpeed} Mb/s)`);
        else text.push("WiFi coverage");
      }
      if (bedroom.noiseLevel)
        text.push(`Noise level (${bedroom.noiseLevel} dB)`);
      if (bedroom.disabledAdapted) text.push("Adapted");
      break;
  }
  return text.length > 0 ? text.join(" · ") : null;
};

export const getEnsuiteBathroomText = (
  bedroom,
  bathrooms,
  locale = "ca-ES"
) => {
  if (bedroom.bathroom) {
    const text = [];

    switch (simplifyLocale(locale)) {
      case "ca":
        text.push("Sí");
        break;
      case "es":
        text.push("Sí");
        break;
      case "en":
      default:
        text.push("Yes");
        break;
    }

    let alreadyAssignedBathroom = null;
    if (bathrooms && bathrooms.length > 0) {
      bathrooms.forEach((bathroom, index) => {
        if (!alreadyAssignedBathroom && bathroom["@id"] === bedroom.bathroom) {
          alreadyAssignedBathroom = index + 1;
        }
      });
    }
    if (alreadyAssignedBathroom) {
      switch (simplifyLocale(locale)) {
        case "ca":
          text.push(`bany ${alreadyAssignedBathroom}`);
          break;
        case "es":
          text.push(`baño ${alreadyAssignedBathroom}`);
          break;
        case "en":
        default:
          text.push(`bathroom ${alreadyAssignedBathroom}`);
          break;
      }
    }
    return text.join(", ");
  }
  return null;
};

export const getFloorName = (floor, locale = "ca-ES") => {
  if (typeof floor !== "number") return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      if (floor === 0) return "Planta baixa";
      if (floor > 0) return `${floor}ª planta`;
      return "Soterrani";
    case "es":
      if (floor === 0) return "Planta baja";
      if (floor > 0) return `${floor}ª planta`;
      return "Sótano";
    case "en":
    default:
      if (floor === 0) return "Ground floor";
      if (floor > 0) return `${floor}ª floor`;
      return "Basement";
  }
};

export const getAmenityTextAndUnit = (amenity) => {
  if (!amenity?.value || !amenity?.units) return null;

  return `${amenity.value} ${amenity.units}`;
};

export const getAmenityName = (amenityCode, locale = "ca-ES") => {
  if (!amenityCode) return null;
  const amenity = amenities.find((a) => a.code === amenityCode);
  if (amenity) {
    switch (simplifyLocale(locale)) {
      case "ca":
        return amenity.name_ca;
      case "es":
        return amenity.name_es;
      case "en":
      default:
        return amenity.name_en;
    }
  }
  return amenityCode;
};

export const getOtaName = (otaCode) => {
  if (!otaCode) return null;
  switch (otaCode.toUpperCase()) {
    case "101HOTELS":
      return "101Hotels.com";
    case "AGODA":
      return "Agoda";
    case "AIRBNB":
      return "Airbnb";
    case "ATRAVEO":
      return "Atraveo";
    case "BOOKING":
      return "Booking.com";
    case "BUNGALOW":
      return "Bungalow";
    case "CASAMUNDO":
      return "Casamundo";
    case "EDOMIZIL":
      return "E-Domizil";
    case "EDREAMS":
      return "eDreams";
    case "EXPEDIA":
      return "Expedia";
    case "FORAVILA":
      return "Foravila Rentals";
    case "HOLIDU":
      return "Holidu";
    case "HOMEAWAY":
      return "HomeAway";
    case "HOMETOGO":
      return "HomeToGo";
    case "HOTELBEDS":
      return "Hotelbeds";
    case "HOUSETRIP":
      return "HouseTrip";
    case "LOCASUN":
      return "Locasun";
    case "MUCHOSOL":
      return "Muchosol";
    case "TRAUM":
      return "Traum-Ferienwohnungen";
    case "VACASOL":
      return "Vacasol";
    case "VILLAPARTNER":
      return "Villapartner";
    case "VRBO":
      return "Vrbo";
    case "WIMDU":
      return "Wimdu";
    default:
      return "Portal";
  }
};

export const getPaymentFrequencyUnit = (code, locale = "ca-ES") => {
  if (!code) return null;
  switch (locale.toLowerCase()) {
    case "ca-es":
      switch (code.toUpperCase()) {
        case "HOUR":
          return "/hora";
        case "DAY":
          return "/dia";
        case "WEEK":
          return "/setmana";
        case "LITER":
          return "/litre";
        case "KW":
          return "/KWh";
        case "ONCE":
          return null;
        default:
          return code.toUpperCase();
      }
    case "es-es":
      switch (code.toUpperCase()) {
        case "HOUR":
          return "/hora";
        case "DAY":
          return "/día";
        case "WEEK":
          return "/semana";
        case "LITER":
          return "/litro";
        case "KW":
          return "/KWh";
        case "ONCE":
          return null;
        default:
          return code.toUpperCase();
      }
    case "en-gb":
    default:
      switch (code.toUpperCase()) {
        case "HOUR":
          return "/hour";
        case "DAY":
          return "/day";
        case "WEEK":
          return "/week";
        case "LITER":
          return "/liter";
        case "KW":
          return "/KWh";
        case "ONCE":
          return null;
        default:
          return code.toUpperCase();
      }
  }
};

export const getBookingStatusName = (code, locale = "ca-ES") => {
  if (!code) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      switch (code.toUpperCase()) {
        case "REQUESTED":
          return "Sol·licitada";
        case "CONFIRMED":
          return "Confirmada";
        case "CANCELED":
        case "CANCELLED":
          return "Cancel·lada";
        case "COMPLETED":
          return "Completada";
        default:
          return code.toUpperCase();
      }
    case "es":
      switch (code.toUpperCase()) {
        case "REQUESTED":
          return "Solicitada";
        case "CONFIRMED":
          return "Confirmada";
        case "CANCELED":
        case "CANCELLED":
          return "Cancelada";
        case "COMPLETED":
          return "Completada";
        default:
          return code.toUpperCase();
      }
    case "en":
    default:
      switch (code.toUpperCase()) {
        case "REQUESTED":
          return "Requested";
        case "CONFIRMED":
          return "Confirmed";
        case "CANCELED":
        case "CANCELLED":
          return "Cancelled";
        case "COMPLETED":
          return "Completed";
        default:
          return code.toUpperCase();
      }
  }
};

export const getBookingStatusColor = (code) => {
  if (!code) return "primary";
  switch (code.toUpperCase()) {
    case "COMPLETED":
      return "primary";
    case "REQUESTED":
      return "warning";
    case "CONFIRMED":
      return "success";
    case "CANCELED":
    case "CANCELLED":
    case "REJECTED":
      return "danger";
    default:
      return "dark";
  }
};

export const getTeamMemberName = (member) => {
  if (!member) return null;
  const words = member.split(" ");
  return words
    .map((word) => word[0].toUpperCase() + word.toLowerCase().substring(1))
    .join(" ");
};

export const getNightsText = (nights, locale = "ca-ES") => {
  if (!nights || nights === 0) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      return `${nights} ${nights !== 1 ? "vespres" : "vespre"}`;
    case "es":
      return `${nights} ${nights !== 1 ? "noches" : "noche"}`;
    case "en":
    default:
      return `${nights} ${nights !== 1 ? "nights" : "night"}`;
  }
};

export const getAdultsText = (number, locale = "ca-ES") => {
  if (!number && number !== 0) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      if (number === 0) return "0 adults";
      if (number !== 1) return `${number} adults`;
      return "1 adult";
    case "es":
      if (number === 0) return "0 adultos";
      if (number !== 1) return `${number} adultos`;
      return "1 adulto";
    case "en":
    default:
      if (number === 0) return "0 adults";
      if (number !== 1) return `${number} adults`;
      return "1 adult";
  }
};

export const getChildrenText = (number, locale = "ca-ES") => {
  if (!number && number !== 0) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      if (number === 0) return "0 nins/es";
      if (number !== 1) return `${number} nins/es`;
      return "1 nin/a";
    case "es":
      if (number === 0) return "0 niños/as";
      if (number !== 1) return `${number} niños/as`;
      return "1 niño/a";
    case "en":
    default:
      if (number === 0) return "0 children";
      if (number !== 1) return `${number} children`;
      return "1 child";
  }
};

export const getBabiesText = (number, locale = "ca-ES") => {
  if (!number && number !== 0) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      if (number === 0) return "0 nadons";
      if (number !== 1) return `${number} nadons`;
      return "1 nadó";
    case "es":
      if (number === 0) return "0 bebés";
      if (number !== 1) return `${number} bebés`;
      return "1 bebé";
    case "en":
    default:
      if (number === 0) return "0 babies";
      if (number !== 1) return `${number} babies`;
      return "1 baby";
  }
};

export const getGuestsText = (guests, locale = "ca-ES") => {
  if (!guests || guests.length === 0) return null;
  const text = [];
  if (guests.adults > 0) text.push(getAdultsText(guests.adults, locale));
  if (guests.children > 0) text.push(getChildrenText(guests.children, locale));
  if (guests.babies > 0) text.push(getBabiesText(guests.babies, locale));
  return text.join(", ");
};

export const getBedroomsText = (number, locale = "ca-ES") => {
  if (!number && number !== 0) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      if (number === 0) return "0 dormitoris";
      if (number !== 1) return `${number} dormitoris`;
      return "1 dormitori";
    case "es":
      if (number === 0) return "0 dormitorios";
      if (number !== 1) return `${number} dormitorios`;
      return "1 dormitorio";
    case "en":
    default:
      if (number === 0) return "0 bedrooms";
      if (number !== 1) return `${number} bedrooms`;
      return "1 bedroom";
  }
};

export const getBathroomsText = (number, locale = "ca-ES") => {
  if (!number && number !== 0) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      if (number === 0) return "0 banys";
      if (number !== 1) return `${number} banys`;
      return "1 bany";
    case "es":
      if (number === 0) return "0 baños";
      if (number !== 1) return `${number} baños`;
      return "1 baño";
    case "en":
    default:
      if (number === 0) return "0 bathrooms";
      if (number !== 1) return `${number} bathrooms`;
      return "1 bathroom";
  }
};

export const getTransportName = (code, locale = "ca-ES") => {
  if (!code) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      switch (code.toUpperCase()) {
        case "PLANE":
          return "Avió";
        case "BOAT":
          return "Vaixell";
        case "NONE":
          return "Sense transport";
        default:
          return code.toUpperCase();
      }
    case "es":
      switch (code.toUpperCase()) {
        case "PLANE":
          return "Avión";
        case "BOAT":
          return "Barco";
        case "NONE":
          return "Sin transporte";
        default:
          return code.toUpperCase();
      }
    case "en":
    default:
      switch (code.toUpperCase()) {
        case "PLANE":
          return "Plane";
        case "BOAT":
          return "Boat";
        case "NONE":
          return "No transport";
        default:
          return code.toUpperCase();
      }
  }
};

export const getGenderName = (code, locale = "ca-ES") => {
  if (!code) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      switch (code.toUpperCase()) {
        case "MALE":
          return "Home";
        case "FEMALE":
          return "Dona";
        case "OTHER":
        default:
          return null;
      }
    case "es":
      switch (code.toUpperCase()) {
        case "MALE":
          return "Hombre";
        case "FEMALE":
          return "Mujer";
        case "OTHER":
        default:
          return null;
      }
    default:
      switch (code.toUpperCase()) {
        case "MALE":
          return "Man";
        case "FEMALE":
          return "Woman";
        case "OTHER":
        default:
          return null;
      }
  }
};

export const getIdTypeName = (code, locale = "ca-ES") => {
  if (!code) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      switch (code.toUpperCase()) {
        case "PASSPORT":
          return "Passaport";
        default:
          return code.toUpperCase();
      }
    case "es":
      switch (code.toUpperCase()) {
        case "PASSPORT":
          return "Pasaporte";
        default:
          return code.toUpperCase();
      }
    default:
      switch (code.toUpperCase()) {
        case "PASSPORT":
          return "Passport";
        default:
          return code.toUpperCase();
      }
  }
};

export const getServiceName = (code, locale = "ca-ES") => {
  if (!code) return null;
  switch (simplifyLocale(locale)) {
    case "ca":
      switch (code.toUpperCase()) {
        case "TOURIST_TAX":
          return "Impost turístic";
        case "SECURITY_DEPOSIT":
          return "Fiança";
        case "CLEANING":
          return "Neteja";
        case "ADDITIONAL_CLEANING":
          return "Neteja addicional";
        case "FINAL_CLEANING":
          return "Neteja final";
        case "AIRPORT_TRANSFER":
          return "Transfer aeroport";
        case "TRANSFER":
          return "Transfer";
        case "CAR_RENTAL":
          return "Lloguer de cotxe";
        case "ELECTRICITY_CONSUMPTION":
          return "Consum elèctric";
        case "HEATING_CONSUMPTION":
          return "Consum calefacció";
        case "CLIMA_CONSUMPTION":
          return "Consum climatització";
        case "FIREWOOD":
          return "Llenya";
        case "BABY_COT":
          return "Cuna";
        case "BABY_CHAIR":
          return "Trona";
        case "JACUZZI":
          return "Jacuzzi";
        case "HEATED_POOL":
        case "HEATED_SWIMMING_POOL":
        case "POOL_HEATING":
          return "Piscina climatitzada";
        case "SUPERMARKET":
          return "Supermercat";
        case "CHEF_AT_HOME":
          return "Chef a domicili";
        case "EXTRA_SINGLE_BED":
          return "Llit individual extra";
        case "OTHER":
          return "Altre";
        default:
          return code.toUpperCase();
      }
    case "es":
      switch (code.toUpperCase()) {
        case "TOURIST_TAX":
          return "Impuesto turístico";
        case "SECURITY_DEPOSIT":
          return "Fianza";
        case "CLEANING":
          return "Limpieza";
        case "ADDITIONAL_CLEANING":
          return "Limpieza adicional";
        case "FINAL_CLEANING":
          return "Limpieza final";
        case "AIRPORT_TRANSFER":
          return "Transfer aeropuerto";
        case "TRANSFER":
          return "Transfer";
        case "CAR_RENTAL":
          return "Alquiler de cotxe";
        case "ELECTRICITY_CONSUMPTION":
          return "Consumo eléctrico";
        case "HEATING_CONSUMPTION":
          return "Consumo calefacción";
        case "CLIMA_CONSUMPTION":
          return "Consumo climatización";
        case "FIREWOOD":
          return "Leña";
        case "BABY_COT":
          return "Cuna";
        case "BABY_CHAIR":
          return "Trona";
        case "JACUZZI":
          return "Jacuzzi";
        case "HEATED_POOL":
        case "HEATED_SWIMMING_POOL":
        case "POOL_HEATING":
          return "Piscina climatizada";
        case "SUPERMARKET":
          return "Supermercado";
        case "CHEF_AT_HOME":
          return "Chef a domicilio";
        case "EXTRA_SINGLE_BED":
          return "Cama individual extra";
        case "OTHER":
          return "Otro";
        default:
          return code.toUpperCase();
      }
    case "en":
    default:
      switch (code.toUpperCase()) {
        case "TOURIST_TAX":
          return "Tourist tax";
        case "SECURITY_DEPOSIT":
          return "Security deposit";
        case "CLEANING":
          return "Cleaning";
        case "ADDITIONAL_CLEANING":
          return "Additional cleaning";
        case "FINAL_CLEANING":
          return "Final cleaning";
        case "AIRPORT_TRANSFER":
          return "Airport transfer";
        case "TRANSFER":
          return "Transfer";
        case "CAR_RENTAL":
          return "Car rental";
        case "ELECTRICITY_CONSUMPTION":
          return "Electricity consumption";
        case "HEATING_CONSUMPTION":
          return "Heating consumption";
        case "CLIMA_CONSUMPTION":
          return "A/C consumption";
        case "FIREWOOD":
          return "Firewood";
        case "BABY_COT":
          return "Baby cot";
        case "BABY_CHAIR":
          return "Baby chair";
        case "JACUZZI":
          return "Jacuzzi";
        case "HEATED_POOL":
        case "HEATED_SWIMMING_POOL":
        case "POOL_HEATING":
          return "Heated pool";
        case "SUPERMARKET":
          return "Supermarket";
        case "CHEF_AT_HOME":
          return "Home Chef";
        case "EXTRA_SINGLE_BED":
          return "Extra single bed";
        case "OTHER":
          return "Other";
        default:
          return code.toUpperCase();
      }
  }
};

// Returns the total amount corresponding to the owner of a given booking service
export const getBookingServiceOwnerTotalPrice = (bookingService, nights) => {
  if (!bookingService) return null;
  // If the payment frequency depends on consumption, the total price
  // is uncalculabe, return null
  if (
    bookingService.paymentFrequency &&
    UNCALCULABLE_FREQUENCIES.includes(bookingService.paymentFrequency)
  ) {
    return null;
  }

  // If the cost price is not defined, get the pvp price by default
  const basePrice = calculateBookingServiceOwnerBasePrice(bookingService);

  let serviceTotalPrice = basePrice;
  if (bookingService.paymentFrequency === "DAY" && nights) {
    serviceTotalPrice = basePrice * nights;
  }

  return serviceTotalPrice;
};

// Returns the text version of the unit price corresponding to the owner of a given booking service
export const getBookingServiceOwnerUnitPriceText = (bookingService, locale) => {
  if (!bookingService) return null;
  if (!bookingService.pvpPrice && !bookingService.costPrice) return null;
  if (!bookingService.paymentFrequency) return null;

  // If the cost price is not defined, get the pvp price by default
  const basePrice = calculateBookingServiceOwnerBasePrice(bookingService);

  const bookingServiceUnitPrice = formatCurrency(basePrice);
  const paymentFrequencyUnit =
    getPaymentFrequencyUnit(bookingService.paymentFrequency, locale) || "";

  return `${bookingServiceUnitPrice}${paymentFrequencyUnit}`;
};

// Returns the text version of the total price corresponding to the owner of a given booking service
export const getBookingServiceOwnerTotalPriceText = (
  bookingService,
  nights,
  locale
) => {
  if (!bookingService) return null;

  const bookingServicePrice = getBookingServiceOwnerTotalPrice(
    bookingService,
    nights
  );

  // If the booking services has no payment frequency or it has a daily payment frequency (it can be calculated in advance),
  // return the calculated total.
  if (
    !bookingService?.paymentFrequency ||
    bookingService.paymentFrequency === "DAY"
  )
    return formatCurrency(bookingServicePrice);

  // If not, return the unit pvp price and the unit text
  return getBookingServiceOwnerUnitPriceText(bookingService, locale);
};

// Returns the name of a given booking service based on the service code
export const getBookingServiceName = (bookingService, locale = "ca-ES") => {
  if (!bookingService?.service) return null;
  return getServiceName(bookingService.service.code, locale);
};

// Returns the text version of the unit price corresponding to the client of a given accommodation service
export const getAccommodationServiceClientUnitPriceText = (
  accommodationService,
  locale
) => {
  if (!accommodationService) return null;
  if (!accommodationService.price && accommodationService.price !== 0)
    return null;
  if (!accommodationService.paymentFrequency) return null;

  const accommodationServiceUnitPrice = formatCurrency(
    accommodationService.price
  );
  const paymentFrequencyUnit =
    getPaymentFrequencyUnit(accommodationService.paymentFrequency, locale) ||
    "";

  return `${accommodationServiceUnitPrice}${paymentFrequencyUnit}`;
};

export const stripHtmlTags = (text) => {
  if (!text?.length) return null;
  return text.replace(/<\/?[^>]+(>|$)/g, "");
};

export const shortenText = (text, wordsNumber) => {
  if (!text?.length) return null;
  const cleanText = stripHtmlTags(text);

  let spacesFound = 0;
  let foundIndex = -1;

  for (let i = 0; i < cleanText.length; i += 1) {
    if (cleanText[i] === " " || i === cleanText.length - 1) {
      spacesFound += 1;
      foundIndex = i;
    }
    if (spacesFound === wordsNumber) break;
  }
  if (foundIndex === -1) return null;

  return foundIndex < cleanText.length - 1
    ? `${cleanText.substring(0, foundIndex)}...`
    : cleanText;
};
