import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import auth from "./auth";
import ui from "./ui";
import accommodation from "./accommodation";
import bookings from "./bookings";
// eslint-disable-next-line import/no-cycle
import booking from "./booking";
import owner from "./owner";
import services from "./services";
import providers from "./providers";
import bookingPolicies from "./booking-policies";
import user from "./user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    ui,
    accommodation,
    bookings,
    booking,
    owner,
    services,
    providers,
    bookingPolicies,
    user,
  },
  strict: process.env.DEV,
});
