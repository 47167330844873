import axios from "@/axios";

export default {
  namespaced: true,
  state: {
    loading: false,
    bookings: [],
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    bookings(state) {
      return state.bookings;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.bookings = [];
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_BOOKINGS(state, payload) {
      state.bookings = payload;
    },
  },
  actions: {
    reset({ commit }) {
      commit("RESET");
    },
    fetchBookings({ state, commit }, payload = {}) {
      commit("SET_LOADING", true);

      let url = "/bookings?";
      if ("pagination" in payload) url += `&pagination=${payload.pagination}`;
      if ("itemsPerPage" in payload)
        url += `&itemsPerPage=${payload.itemsPerPage || state.itemsPerPage}`;
      if ("page" in payload) url += `&page=${payload.page}`;

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            if (response.status === 200) {
              commit("SET_BOOKINGS", response.data["hydra:member"]);
              resolve(response);
            } else {
              reject(
                new Error({
                  status: response.status,
                  message: "Error while fetching all the bookings",
                })
              );
            }
          })
          .catch((error) => {
            reject(error.response);
            // TODO: log error in Sentry
          })
          .finally(() => commit("SET_LOADING", false));
      });
    },
  },
};
